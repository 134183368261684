<template>
  <div class="status-dashboard">
    <getBodyEmail
      app_label="sales"
      model="orderproduct"
      :condition="
        (filter === 1 &&
          email['newStatusName'] === 'preprocess - sended_quote') ||
        filter > 1
      "
      :custumerNotification="email['customerNotification']"
      :stateMain="filter === 1 ? 'sended_quote' : 'in_process'"
      :stateID="email['status_id']"
      :template="filter === 1 ? null : template"
      :email_notification="email['email']"
      :subject="
        email['newStatusName'] === 'sended_quote'
          ? $t('orderupdate', {
              product: email['productName'],
              old: oldStatus,
              new: email['newStatusName']
            })
          : $t('quote')
      "
      :recipient_list="email['customerEmail']"
      ref="getbodyemail"
    />
    <div
      v-show="!loadStatus"
      v-for="(status, key) in statuses"
      :key="`status-${status.pk}`"
      class="status-card"
      :class="{
        noProducts: status.products.length === 0
      }"
    >
      <!-- Títulos de estados de las órdenes -->
      <p class="dashboard-order-title">
        {{ $tc(status.name.split(' - ')[0], 1) }} -
        {{ $tc(status.name.split(' - ')[1], 1) }}
      </p>

      <!-- Zona de gestión de las órdenes -->
      <div
        class="products-container"
        drake="first"
        :data-status="status.pk"
        v-dragula="status.products"
      >
        <v-card
          v-for="product in status.products"
          class="elevation-1 products"
          :key="`product-${product.pk}`"
          :data-block-id="`item+-${product.pk}+-${key}`"
          :data-key="product.pk"
          :style="{ ...stylesList[key].card }"
        >
          <!-- Título de la orden -->
          <div class="d-flex justify-content-between align-items-center">
            <span
              class="dashboard-order-chip"
              :style="{ ...stylesList[key].customerTitle }"
            >
              {{ product.customerName || product.customerFullname }}
            </span>
            <span class="font-weight-medium text-caption text-uppercase px-2">
              {{ product.projectName }}
            </span>
            <v-btn class="p-3" x-small icon @click="expanded(product.pk)">
              <v-icon
                v-text="
                  !expand[product.pk] ? 'fa-chevron-down' : 'fa-chevron-up'
                "
              ></v-icon>
            </v-btn>
          </div>

          <!-- Datos de la orden -->
          <div
            v-show="expand[product.pk] !== undefined && expand[product.pk]"
            class="dashboard-order-data"
          >
            <i-image
              class="product-image"
              :style="{
                width: '5em !important',
                height: '4em !important'
              }"
              :value="product.thumbnail"
              :preview="true"
              :readonly="true"
            />
            <span class="font-weight-medium text-caption">
              {{ product.productName }}
            </span>
          </div>

          <!-- Fechas y botones -->
          <v-row
            v-show="expand[product.pk] !== undefined && expand[product.pk]"
            class="m-0"
          >
            <v-col
              cols="10"
              class="pr-0 pt-0"
              style="max-height: 4rem; overflow-y: auto"
            >
              <p
                class="my-0 py-0 text-caption"
                v-show="modeView === 1"
                v-for="(date, index) in product.datesOrder"
                :key="`order-dates-${product.pk}-${index}`"
              >
                <b>{{ $t(date.type) }}:</b>
                {{ dateFormat(date.date) }}
              </p>
              <p
                class="my-0 py-0 text-caption"
                v-show="modeView === 2"
                v-for="(assigned_user, index) in product.assigned_users"
                :key="`assigned_user-view-${product.pk}-${index}`"
              >
                <b>
                  {{
                    assigned_user.mode == 'designer'
                      ? $t('designer')
                      : $tc('productions', 1)
                  }}:
                </b>
                {{ assigned_user.username }}
              </p>
              <p v-show="modeView === 2 && product.assigned_users.length === 0">
                {{ $t('no_addi_data') }}
              </p>
              <p
                class="my-0 py-0 text-caption"
                v-show="modeView === 1"
                v-for="date in product.dates"
                :key="`dates-${date.pk}`"
              >
                <b>{{ date.type }}:</b>
                {{ dateFormat(date.date) }}
                <v-chip v-if="date.changeDate" x-small color="primary">
                  {{ $tc('change', 1) }}
                </v-chip>
              </p>
            </v-col>
            <v-col cols="2" class="d-flex flex-column g-3 p-0 pb-2">
              <v-btn
                :color="modeView === 1 ? status.color : 'grey'"
                text
                x-small
                @click.prevent="modeView = 1"
              >
                <v-icon> icon-calendar </v-icon>
              </v-btn>
              <v-btn
                v-if="filter != 1"
                :color="modeView === 2 ? status.color : 'grey'"
                text
                x-small
                @click.prevent="modeView = 2"
              >
                <v-icon>icon-team</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!-- Estado y botón de acciones -->
          <v-row
            class="justify-end"
            v-show="expand[product.pk] !== undefined && expand[product.pk]"
            no-gutters
          >
            <v-col
              class="justify-end font-weight-medium text-caption"
              cols="10"
              :style="{ ...stylesList[key].statusIndicate }"
            >
              {{ product.status }}
            </v-col>
            <v-col cols="2">
              <v-tooltip up>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    :color="status.color"
                  >
                    <v-icon>icon-share</v-icon>
                  </v-btn>
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import getBodyEmail from '../email/getBodyEmail.vue'
export default {
  components: {
    getBodyEmail
  },
  props: {
    preStatuses: {
      type: Array
    },
    filter: {
      type: Number
    },
    template: {
      type: Number
    }
  },
  data() {
    return {
      loading: false,
      loadStatus: false,
      stylesList: [],
      statuses: [],
      expand: {},
      nav: 1,
      modeView: 1,
      newStatus: '',
      oldStatus: '',
      product: ''
    }
  },
  watch: {
    preStatuses: {
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.getOrderStatus()
        }
      }
    }
  },
  created() {
    const service = this.$dragula.$service
    service.eventBus.$on('drop', (block) => {
      this.product = Number(block.el.dataset.key)
      this.newStatus = Number(block.container.dataset.status)
      if (this.$refs['getbodyemail'] != undefined) {
        this.$refs['getbodyemail'].getBodyEmail()
        for (let status of this.statuses) {
          let product = status.products.find(
            (item) => item.code == this.email['code']
          )
          if (product != undefined) {
            this.oldStatus = status.name
          }
        }
      }
    })
  },
  methods: {
    expanded(key) {
      this.$set(
        this.expand,
        key,
        this.expand[key] !== undefined ? !this.expand[key] : true
      )
    },
    generateStylesDict(colors) {
      return {
        card: {
          border: `1px solid ${colors.color}`,
          'border-left': `10px solid ${colors.color}`
        },
        customerTitle: {
          color: colors.dark ? 'white' : 'black',
          'background-color': colors.color
        },
        statusIndicate: {
          color: colors.dark ? 'white' : 'black',
          'background-color': colors.color,
          padding: '3px 6px',
          'border-top-left-radius': '0px',
          'border-top-right-radius': '10px',
          'border-bottom-right-radius': '10px',
          'text-align': 'center'
        }
      }
    },
    /**
     * getOrderStatus
     * Método que consulta y filtra los estados de subOrden
     * según la parte del proceso en que se encuentra y
     * que no tienen el campo hidden activo
     *
     * Miguel E. Villamizar R. <mevr02 at gmail.com>
     * Rosana Mendez <rosanamendez5 at gmail.com>
     */
    async getOrderStatus() {
      try {
        this.loadStatus = true
        let statuses = this.preStatuses.filter(
          (event) => event.root == this.filter
        )
        this.statuses = statuses.filter((event) => event.hidden == false)
        this.stylesList = this.statuses.map((item) =>
          this.generateStylesDict({
            color: item.color,
            dark: item.dark
          })
        )
      } finally {
        this.loadStatus = false
      }
    }
  },
  computed: {
    ...mapGetters({
      getTotalNotifications: 'notifications/getTotalNotifications'
    }),
    email() {
      let values = []
      let newStatus = this.statuses.find((item) =>
        item.pk == this.newStatus ? item : ''
      )
      if (newStatus != undefined) {
        values['status_id'] = newStatus.pk
        values['newStatusName'] = newStatus.name
        values['email'] = newStatus.email
        for (let status of this.statuses) {
          let product = status.products.find((item) => item.pk == this.product)
          if (product != undefined) {
            values['productName'] = product.productName
            values['customerEmail'] = product.customerEmail
            values['customerNotification'] = product.customerNotification
            values['code'] = product.code
          }
        }
      }
      return values
    }
  }
}
</script>
<style lang="sass">
.status-dashboard
  display: flex
  flex-direction: column
  width: 400px
  height: auto
  overflow-y: auto
  max-height: 70vh

  .image-print
    // ????
    height: 4rem
    position: inherit

  .status-card
    position: relative
    padding: 10px
    width: 100%

  .status-card.noProducts
    .dashboard-order-title
      color: #888

  .products-container
    min-height: 50px
    padding-bottom: 20px
    background-color: rgb(219 219 219 / 0.5)
    border-bottom-left-radius: 8px
    border-bottom-right-radius: 8px
    display: flex
    flex-direction: column
    gap: 5px

    .products
      cursor: grab
      &:active,
      &:focus
        cursor: grabbing
      &.gu-transit
        cursor: grabbing

  .dashboard-order-title
    margin-bottom: 0
    text-transform: uppercase
    text-align: center
    font-weight: 500
    font-size: 0.75rem
    line-height: 1.25rem
    font-family: "Roboto", sans-serif
    letter-spacing: 0.0333333333em
    padding: 10px
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    border: 1px solid rgb(219 219 219 / 0.5)

  .dashboard-order-chip
    font-weight: 700
    font-size: 0.75rem
    line-height: 1.25rem
    font-family: "Roboto", sans-serif
    letter-spacing: 0.0333333333em
    min-height: 32px
    border-top-right-radius: 10px,
    border-bottom-right-radius: 10px,
    padding: 6px 8px

  .dashboard-order-data
    display: flex
    justify-content: flex-start
    padding-top: 12px
    padding-bottom: 12px

.theme--dark
  .status-dashboard
    .products-container
      background-color: #121212
    .dashboard-order-title
      border: 1px solid rgb(74 74 74 / 0.8)

.gu-mirror
  position: fixed !important
  margin: 0 !important
  z-index: 9999 !important
  opacity: 0.8
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)'
  filter: alpha(opacity=80)
.gu-hide
  display: none !important
.gu-unselectable
  -webkit-user-select: none !important
  -moz-user-select: none !important
  -ms-user-select: none !important
  user-select: none !important
.gu-transit
  opacity: 0.2
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)'
  filter: alpha(opacity=20)
</style>
